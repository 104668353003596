"use client";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export function Navbar() {
	const url = usePathname();
	const [open, setOpen] = useState(false);
	const [pageWidth, setPageWidth] = useState(0);

	useEffect(() => {
		setPageWidth(window.innerWidth);
	}, []);

	const bg = url === "/result" ? "bg-white" : "bg-[#f3f1ef]";
	const style = `w-full flex justify-center items-center py-3 ${bg}`;

	const li_style = "cursor-pointer hover:text-[#ffc000] duration-500 ";

	const gobee = "https://www.gobeesiness.com";

	const links = [
		{ name: "Visite nosso site", url: "/" },
		{ name: "Clube Mentoria", url: "/clube-mentoria" },
		{ name: "Cursos Livres", url: "/cursos-livres" },
		{ name: "Cursos Técnicos", url: "/cursos-tecnicos" },
	];

	return (
		<div className={style}>
			<Box className={"max-w-[1360px] w-full gap-6 md:gap-36 flex items-center p-3 md:pr-6 "}>
				<a target="_blank" href={gobee} rel="noreferrer">
					<img src="/icons/gobeesiness.png" alt="GoBeesiness" className="w-44" />
				</a>
				{pageWidth > 768 ? (
					<ul className={"flex-1 hidden md:flex items-center justify-between list-none gap-3"}>
						{links.map((link, index) => (
							<li key={index}>
								<a
									className={`no-underline text-gray-700 font-bold ${li_style}`}
									target="_blank"
									href={`${gobee}${link.url}`}
									rel="noreferrer"
								>
									{link.name}
								</a>
							</li>
						))}
					</ul>
				) : (
					<>
						<div className="flex-1 flex justify-end">
							<img
								src="https://img.icons8.com/material-outlined/24/000000/menu--v1.png"
								alt="menu"
								className="cursor-pointer hover:opacity-50 duration-500"
								onClick={() => setOpen(true)}
							/>
						</div>
						<Drawer
							anchor="right"
							className="h-[100vh] flex items-center justify-center"
							open={open}
							onClose={() => setOpen(false)}
						>
							<Box sx={{ display: "flex", justifyContent: "end", padding: "1rem" }}>
								<IconButton onClick={() => setOpen(false)}>
									<FontAwesomeIcon icon={faClose} />
								</IconButton>
							</Box>
							<Box
								className="h-[100vh] w-[250px] flex flex-col justify-center items-center"
								role="presentation"
							>
								<List className="flex flex-col justify-center items-center gap-6 ">
									{links.map((link, index) => (
										<ListItem key={index}>
											<ListItemButton
												className={`no-underline text-black ${li_style}`}
												target="_blank"
												href={`${gobee}${link.url}`}
												onClick={() => setOpen(false)}
											>
												<Typography variant="h6">{link.name}</Typography>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Box>
						</Drawer>
					</>
				)}
			</Box>
		</div>
	);
}
