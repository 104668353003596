import { NumericFormat } from "react-number-format";

export function NumberFormatCustom(props: any) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator="."
			decimalSeparator=","
			fixedDecimalScale
			decimalScale={2}
			prefix="R$ "
		/>
	);
}
