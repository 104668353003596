"use client";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

const states: Record<string, number> = {
	Acre: 12,
	Alagoas: 27,
	Amapá: 16,
	Amazonas: 13,
	Bahia: 29,
	Ceará: 23,
	"Distrito Federal": 53,
	"Espírito Santo": 32,
	Goiás: 52,
	Maranhão: 21,
	"Mato Grosso": 51,
	"Mato Grosso do Sul": 50,
	"Minas Gerais": 31,
	Pará: 15,
	Paraíba: 25,
	Paraná: 41,
	Pernambuco: 26,
	Piauí: 22,
	"Rio de Janeiro": 33,
	"Rio Grande do Norte": 24,
	"Rio Grande do Sul": 43,
	Rondônia: 11,
	Roraima: 14,
	"Santa Catarina": 42,
	"São Paulo": 35,
	Sergipe: 28,
	Tocantins: 17,
};

export interface StateCitySelectProps {
	register: Record<string, UseFormRegisterReturn>;
}

export function StateCitySelect({ register }: StateCitySelectProps) {
	const [state, setState] = useState("");
	const [cities, setCities] = useState<string[]>([]);

	useEffect(() => {
		if (!state) {
			return;
		}

		const execute = async () => {
			const data: Record<string, any>[] = await (
				await fetch(
					`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${states[state]}/municipios?orderBy=nome`,
				)
			).json();

			setCities(data.map(city => city.nome));
		};

		execute();
	}, [state]);

	return (
		<fieldset className="w-full grid gap-6 md:grid-cols-2">
			<Autocomplete
				color="secondary"
				disablePortal
				fullWidth
				options={Object.keys(states)}
				onChange={(_, value) => setState(value!)}
				renderInput={params => (
					<TextField {...params} label="Estado" color="secondary" required {...register.uf} />
				)}
			/>

			<Autocomplete
				color="secondary"
				disablePortal
				fullWidth
				options={cities}
				renderInput={params => (
					<TextField {...params} label="Cidade" color="secondary" required {...register.city} />
				)}
			/>
		</fieldset>
	);
}
