"use client";
import { TextField, type TextFieldProps } from "@mui/material";
import type { UseFormRegisterReturn } from "react-hook-form";
import InputMask from "react-input-mask";

export interface TextFieldMaskProps extends Omit<TextFieldProps, "variant"> {
	mask?: string;
	register: UseFormRegisterReturn;
}

export function TextFieldMask({ label, register, ...props }: TextFieldMaskProps) {
	return (
		// @ts-ignore
		<InputMask {...register} mask={props.mask}>
			{/* @ts-ignore */}
			{inputProps => (
				<TextField
					{...inputProps}
					{...props}
					label={label}
					inputRef={register.ref}
					onBlur={register.onBlur}
				/>
			)}
		</InputMask>
	);
}
