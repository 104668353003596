"use client";
import { categoryByUrl, questions, quiz } from "@data";
import { usePathname } from "next/navigation";
import {
	type Dispatch,
	type PropsWithChildren,
	type SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

type Answers = Record<string, string>;

interface DataContext {
	answers: Answers;
	setAnswers: (answers: Answers) => void;
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
	updateAnswers: (category: string) => Promise<void>;
}

const DataContext = createContext<DataContext>({} as DataContext);

export function DataProvider({ children }: PropsWithChildren) {
	const pathname = usePathname();
	const category = categoryByUrl.get(pathname);

	const [answers, setAnswersValue] = useState<Answers>({});
	const [step, setStep] = useState<number>(0);

	const updateAnswers = useCallback(async (category: string, storedAnswers?: any) => {
		const res = await fetch(
			`${process.env.NEXT_PUBLIC_API_URL}/quiz/answers?kind=${process.env.NEXT_PUBLIC_QUIZ_KIND}&category=${category}`,
			{ credentials: "include" },
		);
		if (res.status !== 200) {
			setAnswersValue(storedAnswers);
			return;
		}

		const answers = await res.json();

		setAnswersValue(answers);
		localStorage.setItem("answers", JSON.stringify(answers));
	}, []);

	useEffect(() => {
		if (!category) {
			return;
		}

		const execute = async () => {
			const currentStep = localStorage.getItem("step");
			if (currentStep) {
				setStep(Number(currentStep));
			}

			const storedAnswers = localStorage.getItem("answers");
			updateAnswers(category, JSON.parse(storedAnswers || "{}"));
		};

		execute();
	}, [category, updateAnswers]);

	const setAnswers = useCallback((answers: Answers) => {
		setAnswersValue(answers);
		localStorage.setItem("answers", JSON.stringify(answers));
	}, []);

	const progressData: DataContext = useMemo(
		() => ({
			answers,
			setAnswers,
			setStep,
			step,
			updateAnswers,
		}),
		[answers, setAnswers, step, updateAnswers],
	);

	return <DataContext.Provider value={progressData}>{children}</DataContext.Provider>;
}

export const useData = (): DataContext => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error("useData must be used within an DataProvider");
	}

	return context;
};
