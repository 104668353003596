import type { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@hyoretsu/react-components";

export interface ExternalLinkProps {
	children: string;
	icon: IconDefinition;
	link: string;
}

export function ExternalLink({ children, icon, link }: ExternalLinkProps) {
	return (
		<Link className="hover:cursor-pointer hover:scale-105 duration-300 text-black" href={link}>
			<p className="flex items-center text-sm">
				<FontAwesomeIcon icon={icon} className="mr-3 w-4 h-4" />
				{children}
			</p>
		</Link>
	);
}
