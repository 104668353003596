"use client";
import { NumberFormatCustom, TextFieldMask } from "@components";
import { categoryData } from "@data";
import { Button, Divider, MenuItem, TextField } from "@mui/material";
import { useRouter } from "next/navigation";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { StateCitySelect } from "./components/StateCitySelect";

const lifetimeOptions = [
	"Menos de 6 meses",
	"Até 1 ano",
	"De 1 a 3 anos",
	"De 3 a 5 anos",
	"De 5 a 10 anos",
	"Mais de 10 anos",
];

interface Fields {
	company: {
		city: string;
		cnpj: string;
		lifetime: string;
		monthly_billing: number;
		name: string;
		number_employees: string;
		uf: string;
	};
	contact: {
		email: string;
		name: string;
		phone: string;
	};
}

export function CompanyInfoForm() {
	const { control, register, handleSubmit } = useForm<Fields>();
	const { push } = useRouter();

	const onSubmit: SubmitHandler<Fields> = async ({ contact, company: companyData }) => {
		const { cnpj } = companyData;
		if (cnpj) {
			const res = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj.replace(/\D/g, "")}`);
			if (res.status !== 200) {
				toast.error("CNPJ inválido");
				return;
			}
		}

		const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/users`, {
			body: JSON.stringify({ companyData, ...contact }),
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (res.status !== 201) {
			const { message } = await res.json();
			toast.error(message);

			return;
		}

		localStorage.setItem("quizStarted", "true");
		push([...categoryData.values()][0].url);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-full py-6 flex flex-col justify-center gap-6">
			<TextField
				color="secondary"
				label="Nome da Empresa"
				fullWidth
				required
				type="text"
				{...register("company.name")}
			/>

			<StateCitySelect
				register={{
					city: register("company.city"),
					uf: register("company.uf"),
				}}
			/>

			<fieldset className="w-full grid gap-6 md:grid-cols-2">
				<TextField
					color="secondary"
					label="Faturamento mensal (opcional)"
					fullWidth
					{...register("company.monthly_billing")}
					placeholder="0,00"
					InputProps={{
						inputComponent: NumberFormatCustom,
					}}
				/>

				<Controller
					name="company.number_employees"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<TextField
							type="number"
							color="secondary"
							label="Número de funcionários"
							fullWidth
							required
							{...field}
							onChange={e => {
								const value = Number(e.target.value);
								if (value < 0) {
									e.target.value = "";
									return;
								}

								field.onChange(e);
							}}
						/>
					)}
				/>

				<TextField
					color="secondary"
					label="Tempo de vida"
					fullWidth
					required
					select
					{...register("company.lifetime")}
				>
					{lifetimeOptions.map(option => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</TextField>

				<TextFieldMask
					color="secondary"
					label="CNPJ (opcional)"
					fullWidth
					mask={"99.999.999/9999-99"}
					register={register("company.cnpj")}
				/>
			</fieldset>

			<Divider className="hidden md:pb-2 md:mb-2 md:grid " />

			<TextField
				color="secondary"
				label="Nome do responsável"
				fullWidth
				required
				{...register("contact.name")}
			/>

			<fieldset className="w-full grid gap-6 md:grid-cols-2">
				<TextField
					color="secondary"
					label="Email"
					fullWidth
					required
					type="email"
					{...register("contact.email")}
				/>

				<TextFieldMask
					color="secondary"
					label="Telefone"
					fullWidth
					required
					mask="(99) 99999-9999"
					register={register("contact.phone")}
				/>
			</fieldset>

			<Button
				className="w-full text-xl font-bold tracking-wide"
				type="submit"
				variant="contained"
				size="large"
			>
				Iniciar Diagnóstico Gratuito
			</Button>
		</form>
	);
}
