import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { ExternalLink } from "./components";

export function Footer() {
	return (
		<div className="flex flex-col justify-center items-center bg-white py-6">
			<div className="flex flex-col md:flex-row md:justify-between md:items-center w-full md:max-w-[1360px] md:px-6 px-3">
				<div className="flex flex-col gap-3 pb-3 md:p-0">
					<img
						src="/icons/gobeesiness.png"
						alt="Logo Go Beesiness"
						className="w-[120px] md:w-[200px] object-contain pb-3"
					/>
					<p className="text-sm">
						<span className="font-bold">Go Beesiness</span> - A Escola do Empreendedorismo
					</p>

					<p className="text-sm">
						Este diagóstico é um produto{" "}
						<a
							href="https://www.gobeesiness.com"
							target="_blank"
							className="text-[#ffc000] font-bold no-underline"
							rel="noreferrer"
						>
							Go Beesiness
						</a>
						.
					</p>
				</div>
				<div className="flex flex-col gap-3">
					<h1 className="md:text-xl text-base md:p-0 pt-3 font-bold">Contato</h1>
					<ExternalLink icon={faPhoneAlt} link="https://wa.me/5511953600943">
						(11) 9 5360-0943
					</ExternalLink>

					<ExternalLink icon={faEnvelope} link="mailto:info@gobeesiness.com">
						info@gobeesiness.com
					</ExternalLink>

					<ExternalLink icon={faWhatsapp} link="https://wa.me/5511953600943">
						(11) 9 5360-0943
					</ExternalLink>
				</div>
			</div>
			<div className="pt-6">
				<p className="text-sm">@2023 Go Beesiness - Todos os direitos reservados</p>
			</div>
		</div>
	);
}
