"use client";
import { useData } from "@context/data";
import { categoryByUrl, categoryData, questions } from "@data";
import { Box, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

const quizSteps = [...categoryData.entries()];

const steps = [
	[
		"Informações da Empresa",
		{
			description:
				"Nesta etapa, você fornecerá informações básicas sobre a sua empresa, como nome, localização e tamanho.",
			url: "/",
		},
	],
	...quizSteps,
	[
		"Resultado",
		{
			description: "",
			url: "/resultado",
		},
	],
] as typeof quizSteps;

export function Steps() {
	const { answers } = useData();
	const pathname = usePathname();
	const { push } = useRouter();
	const [quizStarted, setQuizStarted] = useState(false);

	useEffect(() => {
		const status = localStorage.getItem("quizStarted");
		if (status) {
			setQuizStarted(true);
		}
	}, []);

	const step = steps.findIndex(([, { url }]) => url === pathname);

	const currentQuestion = Number(useSearchParams().get("pergunta")!);

	const midQuiz = step !== 0 && step !== steps.length - 1;

	let questionsN: number | undefined;
	if (midQuiz) {
		const [category] = steps[step];
		questionsN = questions[category].length;
	}

	return (
		<>
			<Box
				sx={{ md: { border: "1px solid" }, overflowY: "auto", height: "100%" }}
				className="hidden md:block w-full md:w-1/4 overflow-y-auto scrollbar-hide md:p-6 md:py-3 h-full md:bg-[#fff] rounded-lg border-gray-300"
			>
				<Stepper activeStep={step} orientation={"vertical"}>
					{steps.map(([title, { description, url }], i) => {
						const categoryFinished = !midQuiz
							? true
							: Object.keys(answers).length === questionsN || currentQuestion / questionsN! === 1;
						const possibleStep = !quizStarted
							? false
							: i === 0 || i < step || (i === step + 1 && categoryFinished);

						return (
							<Step key={title}>
								<StepLabel
									className={`flex ${possibleStep && "hover:cursor-pointer"}`}
									onClick={() => {
										if (possibleStep) {
											push(url);
										}
									}}
								>
									<Typography
										className={`py-3 ${
											possibleStep && "sm:hover:translate-y-2 md:hover:translate-x-3  md:hover:translate-y-0"
										} duration-500 ease-in-out font-bold`}
									>
										{title}
									</Typography>
								</StepLabel>

								<StepContent className="hidden md:block max-w-[250px] whitespace-pre-wrap">
									<Typography className="pt-6 pb-3">{description}</Typography>
								</StepContent>
							</Step>
						);
					})}
				</Stepper>
			</Box>
		</>
	);
}
